@font-face {
  font-family: 'RocGrotesk';
  src: local('RocGrotesk'), url('./assets/fonts/RocGrotesk.otf') format('truetype');
  /* font-weight: normal; */
  font-style: initial;
  webkit-font-smoothing: antialiased;
}

@font-face {
  font-family: 'Setimo';
  src: local('Setimo'), url('assets/fonts/Setimo.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SetimoBold';
  src: local('SetimoBold'), url('./assets/fonts/SetimoBold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'SetimoLight';
  src: local('SetimoLight'), url('./assets/fonts/SetimoLight.ttf') format('truetype');
  font-weight: lighter;
}

body {
  font-family: 'Setimo' !important;
  background-color: black !important;
  print-color-adjust: exact;
}

.container {
  background-color: black;
  color: white;
  min-height: 100vh;
  width: '100%';
  text-align: center;
  padding-bottom: 15px;
  position: 'relative';
}
.homecontainer {
  background-color: black;
  color: white;
  height: max-content;
  min-height: 100vh;
  width: '100%';
}

.TyphographyBody {
  font-family: 'setimo';
  font-size: '16px';
  line-height: '22px';
  font-weight: 700;
}
.blankScreen {
  background-color: black;
  height: 100vh;
  width: '100%';
}
input[type='number'] {
  -moz-appearance: textfield;
}
