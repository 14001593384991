.jss7 p {
  color: red;
}
.jss15 p {
  color: red;
}
.jss23 p {
  color: red;
}
.jss31 p {
  color: red;
}
.jss39 p {
  color: red;
}
.jss47 p {
  color: red;
}
.jss55 p {
  color: red;
}
.jss63 p {
  color: red;
}
.jss71 p {
  color: red;
}
